@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Mulish/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Mulish/Mulish-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Mulish";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Mulish/Mulish-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Mulish";
  font-style: italic;
  font-weight: bold;
  src: url("./assets/fonts/Mulish/Mulish-BoldItalic.ttf") format("truetype");
}

body {
  font-family: "Mulish", "Mulish-Bold", sans-serif;
  overflow-y: auto !important;
  color: #474a4d;
  background-color: #f5f9fc;
}

.title,
h1,
h2,
h3,
h4,
h5,
h6,
h7,
.navbar,
th {
  font-family: "Mulish", "Mulish-Bold", sans-serif;
}

.line_through {
  text-decoration: line-through;
  margin-right: 8px;
}

.bold_text {
  font-weight: bold;
}

.pdf_black {
  color: black !important;
}

.btn_ {
  padding: 0;
  margin: 0;
  color: #5864ff;
  cursor: pointer;
}

.modal.show {
  display: block;
  background: #cccccc1c;
}

.modal-content {
  border: none;
  box-shadow: 0px 0px 3px 3px #cccccc42;
}

@media (min-width: 1200px) {
  .modal-dialog {
    margin: 6.75rem auto;
  }
}

.resp_register {
  width: 500px;
  margin: 30px auto;
}

@media (max-width: 1000px) {
  .resp_register {
    width: 100%;
    padding: 15px;
  }

  .resp_register_parent {
    left: 20px !important;
    right: 20px !important;
    transform: translateY(-50%) !important;
    width: auto !important;
  }
}

.form-control {
  min-height: 40px;
  line-height: 30px;
}

strong {
  color: #aaaaaa;
}

span {
  color: #474a4d;
}

select {
  min-height: 35px;
  border: 1px solid #cccccc7d;
  border-radius: 5px;
}

input {
  border: 1px solid #cccccc7d;
  border-radius: 5px;
}

.leftmenu {
  background: #5864ff;
  color: #fff;
  min-width: 200px;
  border: none;
  /* overflow:hidden;*/
  position: relative;
  position: fixed;
  /* height: 100vh; */
  top: 60px;
  bottom: 0px;
  z-index: 999;
  transition: 0.2s;
  box-shadow: 3px 4px 4px 3px rgb(166 165 165 / 30%);
  padding-top: 15px;
}

.leftmenu button#menuClosebtn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
}

.leftmenu.leftmenuhidden {
  width: 50px;
}

table thead span i.fa {
  color: #006dff;
}

th i.span {
  color: #006dff;
}

.rightcontent {
  margin-top: 59px;
  margin-left: 210px;
  padding-right: 26px;
  padding-left: 10px;
  padding-top: 10px;
}

.rightcontent.leftmenuhidden {
  margin-left: 50px;
}

.nav-link {
  color: #0d6efd;
}

.nav-link:hover {
  color: #0d6efd;
  opacity: 0.8;
}

.leftmenu .nav-link {
  padding: 15px;
}

.top-menu a.nav-link {
  color: #fff;
}

.top-menu {
  background: #5864ff;
  color: #fff;
  box-shadow: 1px 3px 4px 3px rgb(166 165 165 / 30%);
  z-index: 9;
}

.leftmenu span,
.leftmenu i {
  color: #aaafcd;
  font-size: 18px;
}

.offcanvas-body {
  overflow: hidden;
}

.leftmenu .sub-menu span,
.leftmenu .sub-menu i {
  color: #aaafcd;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
}

.leftmenu a:hover {
  background: #6973ff;
  color: #fff;
}

.leftmenu .nav-link.show {
  background: #6973ff;
}

.leftmenu .dropdown {
  position: static;
}

.leftmenu .dropdown .dropdown-menu {
  position: absolute !important;
  inset: 0px auto auto 0px !important;
  margin: 0px !important;
  transform: translate(200px, 0px) !important;
  top: 0 !important;
  bottom: 0 !important;
  border-radius: 0;
  overflow-y: auto;
  z-index: 1000;
}

.image-placer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
}

.leftmenu.leftmenuhidden .dropdown .dropdown-menu {
  transform: translate(50px, 0px) !important;
}

.leftmenu .sub-menu-title {
  font-size: 17px;
  font-weight: bold;
  font-family: "Mulish", "Mulish-Bold", sans-serif;
  padding-left: 15px;
}

.leftmenu .dropdown-toggle::after {
  content: "";
  border: none;
}

.leftmenu li {
  display: block;
  width: 100%;
}

.leftmenu a:hover {
  background: #6973ff;
  color: #fff;
}

.leftmenu .nav-link {
  padding: 0 10px 10px 10px;
  display: block;
  width: 100%;
}

.leftmenu li a {
  color: #fff;
}

.dropdown-menu li a {
  color: #000;
  line-height: 40px;
  vertical-align: middle;
}

.leftmenu li i {
  width: 40px;
  display: inline-block;
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  background: #5864ff;
  border: #5864ff;
}

path:hover {
  opacity: 0.7;
}

.searchbox {
  /*min-width: 150px;*/
  position: relative;
  padding: 2px;
  border-radius: 2px;
  margin: 0 10px;
}

#accountGroups {
  position: relative;
}

#accountGroups::before {
  content: '';
  position: absolute;
  left: -10px;
  width: 1px;
  top: 50%;
  background-color: #fff;
  height: 20px;
  transform: translateY(-50%);
}

#accountGroups::after {
  content: '';
  position: absolute;
  right: -10px;
  width: 1px;
  top: 50%;
  background-color: #fff;
  height: 20px;
  transform: translateY(-50%);
}

.searchbox::before {
  content: '';
  position: absolute;
  right: -10px;
  width: 1px;
  top: 50%;
  background-color: #fff;
  height: 20px;
  transform: translateY(-50%);
}

.navbar-collapse {
  flex-basis: auto !important;
  flex-grow: 0 !important;
}

.searchbox .dropdown-toggle::after {
  position: absolute;
  right: 8px !important;
  top: 18px !important;
}

.searchbox .dropdown-menu {
  max-height: 500px;
  overflow-y: scroll;
}

@media (max-width: 1445px) {
  #topsearchbox {
    display: none;
  }
}

@media (min-width: 1200px) {
  /* .searchbox {
    min-width: 200px;
  } */

  .login-box {
    transform: scale(1.5);
  }

  .login-box.register {
    transform: scale(1);
  }
}

@media (min-width: 1445px) {
  /* .searchbox {
    min-width: 250px;
  } */
}

/*.tooltip {
    display:none;
    position:absolute;
    top : 200px;
    left: 200px;
    z-index:99;
    background:#454040;
    color:#fff;

}
*/

.modal.show {
  display: block;
}

/*Timeline*/
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 4%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
  width: 99%;
  float: left;
  clear: left;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 93%;
  float: left;
  border: 1px solid #d4d4d4;
  /*border-radius: 2px;*/
  /*padding: 20px;*/
  position: relative;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline > li > .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  right: -12px;
  /*background-color: #999999;*/
  z-index: 100;
  /*
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  */
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-badge > a {
  color: #c5c7c5 !important;
}

.timeline-badge a:hover {
  color: #000 !important;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  padding: 20px;
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-footer {
  padding: 20px;
  background-color: #f4f4f4;
}

.timeline-footer > a {
  cursor: pointer;
  text-decoration: none;
}

.tooltip {
  position: absolute;
  z-index: 1020;
  display: block;
  visibility: visible;
  padding: 5px;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.tooltip.top {
  margin-top: -2px;
}

.tooltip.right {
  margin-left: 2px;
}

.tooltip.bottom {
  margin-top: 2px;
}

.tooltip.left {
  margin-left: -2px;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-left: 0;
  border-left: 0 solid transparent;
  border-right: 5px solid transparent;
  border-top: 0 solid #000;
}

.tooltip.left .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-left: 0;
  border-left: 0 solid transparent;
  border-right: 5px solid transparent;
  border-top: 0 solid #000;
}

.tooltip.bottom .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-left: 0;
  border-left: 0 solid transparent;
  border-right: 5px solid transparent;
  border-top: 0 solid #000;
}

.tooltip.right .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-left: 0;
  border-left: 0 solid transparent;
  border-right: 5px solid transparent;
  border-top: 0 solid #000;
}

.tooltip-inner {
  width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #313131;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

.timeline > li.timeline-inverted {
  float: right;
  clear: right;
  margin-top: 30px;
  margin-bottom: 30px;
}

.timeline > li:nth-child(2) {
  margin-top: 60px;
}

.timeline > li.timeline-inverted > .timeline-badge {
  left: -12px;
}

@media (max-width: 767px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    float: left;
    clear: left;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .timeline > li.timeline-inverted {
    float: left;
    clear: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .timeline > li.timeline-inverted > .timeline-badge {
    left: 28px;
  }
}

/*Timeline*/
.modal-dialog {
  max-width: 80% !important;
}

span[class="btn"] {
  color: #5864ff;
}

button.menuCloseButton {
  background: transparent;
  border: 1px solid;
  color: #fff;
  border-radius: 26px;
  width: 29px;
  position: absolute;
  right: 4px;
  top: 6px;
}

.leftmenuhidden button.menuCloseButton {
  display: none;
}

.table-docs {
  width: 100%;
  margin-bottom: 1rem;
  color: #474a4d;
  vertical-align: top;
  border-color: #e5edf5;
}

.table-docs {
  width: 100%;
  margin-bottom: 1rem;
  color: #1d1d1d;
  vertical-align: top;
  border-color: #e5edf5;
}

.table-docs th {
  border-bottom: 2px solid #f1f0f0;
  padding-bottom: 20px;
  vertical-align: top;
  color: #03152d;
}

.table-docs td {
  padding: 5px;
  vertical-align: middle;
}

.table-docs button.btn {
  background-color: #b8aeae1a;
  color: #565656;
  font-size: 14px;
  border-radius: 0;
  text-decoration: none;
  min-width: 190px;
  min-height: 40px;
  line-height: 30px;
}

.graph-text-bold {
  font-weight: bold;
}

.table-docs .btn.red {
  background-color: #f16448;
}

.table-docs .btn.green {
  background-color: #169431;
}

.table-docs .btn.orange {
  background-color: #ff9900;
}

.table-docs .btn.light {
  background-color: #e5e5e5;
  color: #797979;
  min-width: 190px;
  min-height: 40px;
}

.inputseach {
  position: relative;
  display: block;
  outline: none;
}

.inputseach input {
  border: none;
  border-bottom: 2px solid #cccccc4d;
  outline: none;
  width: 100%;
}

.inputseach input:active,
.inputseach input:focus-visible {
  border: none;
  border-bottom: 2px solid #cccccc8d;
  outline: none;
}

.inputseach .fa {
  position: absolute;
  right: 9px;
  top: 5px;
  color: #cccccc80;
}

.status_div {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  min-height: 40px;
}

.status_div .status_indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.success {
  background-color: rgb(209, 235, 209);
}

.danger {
  background-color: rgb(231, 203, 203);
}

.success .status_text {
  color: green;
  text-transform: capitalize;
}

.success .status_indicator {
  background-color: green;
}

.danger .status_text {
  color: red;
  text-transform: capitalize;
}

.danger .status_indicator {
  background-color: red;
}

.support_filter {
  display: flex;
  align-items: stretch;
}

.support_select {
  border: 1px solid #ccc;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.card-header {
  background-color: white !important;
}

.card-header button.btn,
.card-header span.btn {
  margin-right: 10px;
}

.card {
  border: none !important;
  margin-top: 20px;
}

.support_types_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.support_types_container input {
  margin: 0px 8px;
  user-select: none;
  border-radius: 5px;
  border: 1px solid #afadad;
}

.support_types_container button {
  margin: 0px 5px;
}

.support_header {
  position: absolute;
  top: 0px;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  transform: translateY(-50%);
  background-color: white;
}

.datetimepicker {
  padding: 4px;
  min-width: 216px;
}

button.dropdown-toggle {
  background-color: white;
  border: 1px solid #ced4da;
}

/* Month Selector */
.flatpickr-monthSelect-months {
  margin: 10px 1px 3px 1px;
  flex-wrap: wrap;
}

.flatpickr-monthSelect-month {
  background: none;
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5px;
  justify-content: center;
  padding: 10px;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  width: 33%;
}

.flatpickr-monthSelect-month.disabled {
  color: #eee;
}

.flatpickr-monthSelect-month.disabled:hover,
.flatpickr-monthSelect-month.disabled:focus {
  cursor: not-allowed;
  background: none !important;
}

.flatpickr-monthSelect-theme-dark {
  background: #3f4458 !important;
}

.flatpickr-monthSelect-theme-dark .flatpickr-current-month input.cur-year {
  color: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-prev-month,
.flatpickr-monthSelect-theme-dark .flatpickr-months .flatpickr-next-month {
  color: #fff;
  fill: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month {
  color: rgba(255, 255, 255, 0.95);
}

.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month:focus {
  background: #e6e6e6;
  cursor: pointer;
  outline: 0;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month:focus {
  background: #646c8c;
  border-color: #646c8c;
}

.flatpickr-monthSelect-month.selected {
  background-color: #569ff7;
  color: #fff;
}

.flatpickr-monthSelect-theme-dark .flatpickr-monthSelect-month.selected {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #80cbc4;
}

.leftmenuhidden .instance_more_details {
  left: 75px !important;
}

.grid_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.grid_child {
  border-radius: 5px;
  position: relative;
  padding: 10px;
  width: 500px;
  box-shadow: 1px 1px 3px 1px grey;
}

.info-title {
  font-weight: bold;
}

.info-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
}

.success_icon {
  background-color: green;
  color: white;
}

.failed_icon {
  background-color: red;
  color: white;
}

.icon_container {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.icon_container i {
  position: absolute;
  font-size: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info-group {
  margin: 5px 0px;
}

.bucket_name {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.bucket_icon_container {
  width: 30px;
  height: 30px;
}

.bucket_icon {
  height: 100%;
  text-align: center;
}

.child_more {
  position: absolute;
  padding: 10px;
  top: calc(100% - 5px);
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s;
  opacity: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 1px 4px 3px 1px grey;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 10;
}

.grid_child:hover > .child_more {
  opacity: 1;
  max-height: 1000px;
}

.flex_horizontal {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
}

.buckets_summary {
  margin: 10px 0px 20px 0px;
}

.active_filter {
  box-shadow: 1px 1px 3px 1px #5864ff !important;
  border: 2px solid rgb(88, 100, 255);
  background: rgba(88, 100, 255, 0.2);
}

.toastcenter {
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate(-50%, -50%);
}

.toastcenter .toast-body {
  color: #fff;
}

.click-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  user-select: none !important;
  opacity: 0.4 !important;
}

button.dropdown-toggle {
  max-width: 500px;
}

#searchbackground {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(2px);
}

#searchResult {
  position: fixed;
  top: 58px;
  left: 200px;
  z-index: 999999999;
  background: #f0f0f0;
  min-width: 500px;
  min-height: 250px;
  border-radius: 3px;
  color: #000;
  padding: 15px;
}

.cost-table th,
.cost-table td {
  white-space: nowrap;
  padding-right: 15px;
}

.center {
  text-align: center;
}

.red_text {
  color: #f597a0;
  /* font-weight: bold;*/
}

.green_text {
  color: #23c023;
  /* font-weight: bold;*/
}

.btn-primary-soft {
  color: #0f6fec;
  background-color: rgba(15, 111, 236, 0.1);
}

.btn-primary-soft:hover, .btn-primary-soft:focus {
  color: #fff;
  background-color: #0f6fec;
}

.btn-danger-soft {
  color: #d6293e;
  background-color: rgba(214, 41, 62, 0.1);
}

.btn-danger-soft:hover {
  color: #fff;
  background-color: #d6293e;
}

.btn-success-soft {
  color: #0cbc87;
  background-color: rgba(12, 188, 135, 0.1);
}

.btn-success-soft:hover {
  color: #fff;
  background-color: #0cbc87;
}

.btn_blue {
  background-color: #5864ff;
  border: none;
  border-radius: 0px;
  padding: 5px 10px;
  color: white;
}

.btn_loading {
  opacity: 0.4;
  pointer-events: none;
}

.btn_blue:hover {
  background-color: #5864ff;
  box-shadow: 1px 1px 3px 1px grey;
  color: white;
}

.btn_blue:active {
  background-color: #5864ff;
  box-shadow: 1px 1px 3px 1px grey;
}

.btn_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.dropdown-item.active {
  background-color: #e9ecef !important;
  color: #aaaaaa !important;
}

.divider {
  padding: 5px 12px;
  padding-top: 8px;
  border-top: 1px solid rgb(228, 228, 228);
  color: #828ecf;
  font-weight: bold;
  margin-top: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.divider i {
  float: right;
  vertical-align: middle;
}

.loading_38 {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading_39 {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading_img_42 {
  width: 100%;
  height: 100%;
}

.height-25 {
  height: 25px !important;
}

.datepicker-dropdown {
  top: 220px !important;
}

.wid-mar-right {
  width: 18px;
  margin-right: 6px;
}

#topsearchbox .form-control {
  border-radius: 0;
}
#topsearchbox .input-group-text {
  color: #fcfcfc;
  background-color: #5864ff;
  border-radius: 0;
}
.alarm_header h4 {
  color: inherit !important;
}

.color-inherit {
  color: inherit !important;
}

.wid-18 {
  width: 18px;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.overflow {
  max-width: 100%;
  overflow: auto;
}

.flex-start button.btn, .flex-end button.btn {
  margin-right: 0px !important;
}

.nav-link:focus, .nav-link:hover {
  color: inherit !important;
}

.nav-link {
  cursor: pointer;
  display: inline-block;
}

.no-scroll{
  overflow-y: hidden !important;
}

strong {
  font-weight: bold !important;
  color: inherit !important;
}

html {
  overflow: hidden !important;
}

body {
  max-height: 100vh;
  overflow: auto;
  transition: 0.2s;
}

.flatpickr-calendar.static.open{
  right: 0 !important;
}